<template>
  <AppLayout>
    <template v-slot:appContent>
      <div class=" d-sm-flex justify-content-between align-items-center pb-1  ">
        <h4 class="">SMS Marketing</h4>
        <div>
          <router-link :to="{...previousRoute}">
            <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
          </router-link>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
              <h3>Recipients</h3>
              <label>Customer </label>
              <div class="form-group">
                <div v-if="!smsToAnyone" class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" v-model="checkedAllCustomerTypeValue" id="customerTypeAll" >
                  <label class="form-check-label" for="customerTypeAll">All Type</label>
                </div>
                <template v-if="!smsToAnyone">
                  <div  v-for="(customerType, index) in customerTypeOptions" :key="index" class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" v-model="checkedCustomerTypeValue" :id="`customerType-${index}`" :value="customerType.value">
                    <label class="form-check-label" :for="`customerType-${index}`">{{ customerType.name }}</label>
                  </div>
                </template>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" v-model="smsToAnyone" id="smsToAnyone" >
                  <label class="form-check-label" for="smsToAnyone">SMS To Anyone</label>
                </div>
              </div>
              <label v-if="!smsToAnyone">State and territories </label>
              <div v-if="!smsToAnyone" class="form-group">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" v-model="checkedAllStateValue" id="stateCheckboxAll" :value="null">
                  <label class="form-check-label" for="stateCheckboxAll">All State</label>
                </div>
                <div v-for="(state, index) in stateOptions" :key="index" class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" v-model="checkedStateValue" :id="`stateCheckbox-${index}`" :value="state.value">
                  <label class="form-check-label" :for="`stateCheckbox-${index}`">{{ state.name }}</label>
                </div>
              </div>
              <div v-if="!smsToAnyone" class="form-group">
                <label>Post Codes </label>
                <input v-model="postCodeInputText" type="text" class="form-control" placeholder="Ex : 2532,3012,4012">
              </div>
              <div v-else class="form-group">
                <label>Phone Numbers </label>
                <input v-model="phoneNumbers" type="text" class="form-control" placeholder="Ex : 0415121210,0411111111,0445451210">
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12">
              
              <div class="form-group">
                <div>
                  <label>SMS Text</label>
                  <textarea v-model="smsText" id="usageCondition" class="form-control"></textarea>
                </div>
                <div class="text-danger" v-if="errors.sms">{{ errors.sms }}</div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 text-right">
              <button type="button" @click="sendSmsDMHandler" class="btn btn-primary ">Send</button>
            </div>
          </div>
        </div>
      </div>

    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

// mixins
import Authorization from "@/components/backEnd/mixins/Authorization";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
// core packages
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CouponEdm",
  components: {
    AppLayout,
  },
  mixins: [ShowToastMessage, Loader, Authorization],
  data() {
    return {
      getSettingsParams: {
        type: ['default'],
        key: ['default_state'],
      },
      smsToAnyone: false,
      phoneNumbers: "",
      checkedAllStateValue: true,
      checkedAllCustomerTypeValue: true,

      smsText: null,
      postCodeInputText: null,

      checkedStateValue: [],
      checkedCustomerTypeValue: [],
      inputPostCode: [],
      errors: {
        type: null,
        state: null,
        postCode: null,
        sms: null
      },
    }

  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      settingDefaultState: 'appSettings/settingDefaultState',
    }),

    stateOptions() {
      return [...this.settingDefaultState.value]
    },

    customerTypeOptions() {
      return [
        {name: 'Guest', value: 0},
        {name: 'Home', value: 1},
        {name: 'Business', value: 2},
        
      ];
    },

  },
  watch: {
    smsToAnyone(smsToAnyoneCurrentValue){
   
      if(smsToAnyoneCurrentValue === true){
        this.checkedCustomerTypeValue = [];
        this.checkedAllStateValue = [];
        this.checkedAllStateValue = false;
        this.checkedAllCustomerTypeValue = false;
        this.smsText = null;
        this.postCodeInputText = null;
        this.inputPostCode = [];
      }
     
    },
    checkedAllCustomerTypeValue(checkedAllCustomerTypeValue) {
      if(this.smsToAnyone === true){
        return;
      }
      this.checkedCustomerTypeValue = checkedAllCustomerTypeValue !== true
          ? this.checkedCustomerTypeValue
          : [];
    },
    checkedCustomerTypeValue(checkedCustomerTypeValue) {
       if(this.smsToAnyone === true){
        return;
      }
      this.checkedAllCustomerTypeValue = checkedCustomerTypeValue.length <= 0;
    },
    checkedAllStateValue(checkedAllStateValue) {
      if(this.smsToAnyone === true){
        return;
      }
      this.checkedStateValue = checkedAllStateValue !== true
          ? this.checkedStateValue
          : [];
    },
    checkedStateValue(checkedStateValue) {
      if(this.smsToAnyone === true){
        return;
      }
      this.checkedAllStateValue = checkedStateValue.length <= 0;
    },
    postCodeInputText(postCodeInputText) {
      if(this.smsToAnyone === true){
        return;
      }
      this.inputPostCode = postCodeInputText === null || postCodeInputText === ''
          ? []
          : postCodeInputText.split(",").map(singlePostCode => parseInt(singlePostCode));

      let postcode = this.inputPostCode;
      this.inputPostCode = postcode.filter(item => !isNaN(item));

    }
  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      smsMarketingSendNotification: 'smsMarketings/smsMarketingSendNotification',
      sendMultipleSms: 'smsMarketings/sendMultipleSms',

    }),

    async resetErrors() {
      this.errors = {
        type: null,
        state: null,
        postCode: null,
        sms: null
      };
    },

    async resetData() {
      this.checkedAllStateValue = true;
      this.checkedAllCustomerTypeValue = true;
      this.smsText = null;
      this.postCodeInputText = null;
      this.checkedStateValue = [];
      this.checkedCustomerTypeValue = [];
      this.inputPostCode = [];
      this.smsToAnyone = false;
      this.phoneNumbers = "";
    },

    async getSettingsList() {
      await this.getSettings(this.getSettingsParams);
    },

    async sendSmsDMHandler() {
      if (this.smsToAnyone) {
        if (this.smsText === null || this.smsText === '' || this.phoneNumbers === null || this.phoneNumbers === '') {
          const toastObj = {message: 'Please enter sms text and phone number properly.', type: 'error'};
          await this.showToastMessage(toastObj);
          return;
        }

        // parsed phone numbers from string
        const parsedNumbers = this.phoneNumbers.includes(',') ? this.phoneNumbers.split(',').map(number => number.trim()) : [this.phoneNumbers];
        if (parsedNumbers.length <= 0) {
          const toastObj = {message: 'Please enter phone numbers.', type: 'error'};
          await this.showToastMessage(toastObj);
          return;
        }
        // check if all data is valid
        const isAllDataValid = parsedNumbers.every(number => number.length === 10 && !isNaN(number));
        if (!isAllDataValid) {
          const toastObj = {message: 'Please enter valid phone numbers.', type: 'error'};
          await this.showToastMessage(toastObj);
          return;
        }
        let data = {
          body: this.smsText,
          recipients: parsedNumbers.map((number) => (`+61${number}`))
        };
        console.log(data);
        this.loader(true);
        this.sendMultipleSms(data).then(async response => {
          if (response.message) {
           this.showToastMessage(response);
          }
          if (response.status === 201 || response.status === 200) {
            this.loader(false);
            const toastObj = {message: 'SMS sent successful.', type: 'success'};
            await this.showToastMessage(toastObj);

            await this.resetErrors();
            await this.resetData();
            return;
          }
          this.loader(false);
          this.errors.type = response?.errors?.type?.[0] ?? '';
          this.errors.state = response?.errors?.state?.[0] ?? '';
          this.errors.postCode = response?.errors?.post_code?.[0] ?? '';
          this.errors.sms = response?.errors?.sms?.[0] ?? '';
        });
      }
      else {
        let data = {
        type: this.checkedAllCustomerTypeValue !== true ? this.checkedCustomerTypeValue : null,
        state: this.checkedAllStateValue !== true ? this.checkedStateValue : null,
        postCode: this.inputPostCode.length > 0 ? this.inputPostCode : null,
        sms: this.smsText
      };

      this.smsMarketingSendNotification(data).then(async response => {
        if (response.status === 201 || response.status === 200) {
          const toastObj = {message: 'SMS send successful.', type: 'success'};
          await this.showToastMessage(toastObj);

          await this.resetErrors();
          await this.resetData();
          return;
        }

        this.errors.type = response?.errors?.type?.[0] ?? '';
        this.errors.state = response?.errors?.state?.[0] ?? '';
        this.errors.postCode = response?.errors?.post_code?.[0] ?? '';
        this.errors.sms = response?.errors?.sms?.[0] ?? '';

        if (response.message) {
          this.showToastMessage(response);
        }
      })
      }
      
    }
  },
  async mounted() {
    await this.getSettingsList();
  },

}
</script>

<style scoped>

</style>
